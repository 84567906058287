import React, { useState } from 'react';
import './Main.css';
import banner from '../../Assets/banner-shape-01.png';
import BackToTopButton from '../BackTotop/BackToTop';

const Main = () => {


  const [skew, setsKew] = useState(false);

  const ChnageSkew = () => {
    if (window.scrollY >= 230) {
      setsKew(true)
    } else {
      setsKew(false)
    }
  }

  window.addEventListener('scroll', ChnageSkew)



  return (
    <>
      <div className="main-wrapper" id='home'>

        <div className={"skewOne " + (skew ? 'skewOneNew' : '')} ></div>
        <div className={"skewtwo " + (skew ? 'skewOneNew' : '')} ></div>
        <div className="main">

          <div className="main-l">
            <h4>Learn New Skills & Popular Courses</h4>
            <h1>For Every Student, Every <span>Classroom</span>.</h1>
            <p>Dramatically supply transparent deliverables before backward
              comp internal or "organic" sources. Competently leverage other.
              Explore Courses</p>
            <button className='animatebtn mainbtn'><span>Explore</span></button>
          </div>
          <div className="main-r">
            <img src={banner} alt="" />
          </div>
        </div>
        <BackToTopButton />

      </div>
    </>
  )
}

export default Main