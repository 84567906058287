
import Mentor1 from '../../Assets/mentor1.jpg';
import Mentor2 from '../../Assets/mentor2.jpg';
import Mentor3 from '../../Assets/mentor3.jpg';
import Mentor4 from '../../Assets/mentor4.jpg';
import Mentor5 from '../../Assets/mentor5.jpg';
import Mentor6 from '../../Assets/hasnain.jpg';

export const WorkData = [
    {
        Image:Mentor1,
        title:'Donna Yancey',
        stitle:'Digital Marketer',
    },
    {
        Image:Mentor6,
        title:'Hasnain',
        stitle:'HR Professional',
        },
    {
        Image:Mentor3,
        title:'Sohaib',
        stitle:'Enginer',
    },
    {
        Image:Mentor4,
        title:'Olivia',
        stitle:'Graphic designer',
    },
    {
        Image:Mentor5,
        title:'Noah',
        stitle:'Freelancer',
    },
    {
        Image:Mentor2,
        title:'Amelia',
        stitle:'Content Writer',
    }
]